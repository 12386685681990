package tta.destinigo.talktoastro.feature_post

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.request.PostRequest
import tta.destinigo.talktoastro.feature_post.data.response.PostDataNew
import tta.destinigo.talktoastro.feature_post.domain.PostListRepo
import tta.destinigo.talktoastro.shared.remote.Resources

class PostScreenComponent(
    context: ComponentContext,
    private val onBackClick: () -> Unit,
    private val onPostClick:(String) -> Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)

    private val api: PostListRepo by inject()

    private val _postState = MutableStateFlow<List<PostDataNew>>(emptyList())
    val postState: StateFlow<List<PostDataNew>> = _postState

    private val _isLoading = MutableStateFlow(false)
    val isLoading: StateFlow<Boolean> = _isLoading

    private var currentPage = 1
    private var isLastPage = false

    fun backClick() = onBackClick.invoke()
    fun postCardClick(id: String) = onPostClick.invoke(id)
    init {
        lifecycle.doOnCreate {
            coroutineScope.launch {
                fetchPostData()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private suspend fun fetchPostData() {
        if (_isLoading.value || isLastPage) return // Prevent redundant calls
        _isLoading.emit(true)

        try {
            api.getPostListData(PostRequest(currentPage.toString())).collectLatest { resource ->
                when (resource) {
                    is Resources.Error -> {
                        Logger.d { "fetchPostData Error: ${resource.msg}" }
                    }

                    is Resources.Loading -> {
                        Logger.d { "fetchPostData Loading: ${resource.isLoading}" }
                    }

                    is Resources.Success -> {
                        if (resource.data?.data?.isEmpty() == true) {
                            isLastPage = true
                            Logger.d { "No more chat history pages to load." }
                        }else {
                            val currentData = _postState.value
                            val updatedData = currentData + (resource.data?.data ?: emptyList())
                            _postState.emit(updatedData)
                            currentPage++
                        }
                    }
                }
            }
        } finally {
            _isLoading.emit(false)
        }
    }



    private suspend fun addLickRequest(request: LikeRequest) {
        api.addLick(request).collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "addLickRequest on Error State ${it.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "addLickRequest on Loading State ${it.isLoading}" }
                }
                is Resources.Success -> {
                    Logger.d { "addLickRequest on Success State  ${it.data}" }
                }
            }
        }
    }

    fun loadNextPage() = coroutineScope.launch {
        fetchPostData()
    }
    fun onLick(request: LikeRequest) = coroutineScope.launch {
        addLickRequest(request = request)
    }
}

