package tta.destinigo.talktoastro.feature_post

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.request.SubmitCommentResponse
import tta.destinigo.talktoastro.feature_post.data.response.Comment
import tta.destinigo.talktoastro.feature_post.data.response.PostDetailsResponseData
import tta.destinigo.talktoastro.feature_post.domain.PostListRepo
import tta.destinigo.talktoastro.shared.remote.Resources

class PostDetailsComponent(
    context: ComponentContext,
    private val onBackClick: () -> Unit,
    private val _postData: String,
) : ComponentContext by context, KoinComponent {
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: PostListRepo by inject()
    val postId get() = _postData

    fun onNavigateBack() = onBackClick.invoke()
    private val _postDetailsState = MutableStateFlow<PostDetailsResponseData?>(null)
    val postDetailsState: StateFlow<PostDetailsResponseData?> = _postDetailsState


    private val _postCommentsState = MutableStateFlow<List<Comment>>(emptyList())
    val postCommentsState: StateFlow<List<Comment>> = _postCommentsState


    private val _isCommentLoading = MutableStateFlow(false)
    val isCommentLoading: StateFlow<Boolean> = _isCommentLoading

    private val _postCommentSuccess = MutableStateFlow(false)
    val postCommentSuccess: StateFlow<Boolean> = _postCommentSuccess

    private val _isLoading = MutableStateFlow(false)
    val isLoading: StateFlow<Boolean> = _isLoading
    init {
        lifecycle.doOnCreate {
            coroutineScope.launch {
                getPostDetails()
                getAllComment()
            }
        }

        lifecycle.doOnDestroy {

        }
    }



    private suspend fun getPostDetails(){
        _isLoading.emit(true)
        try {
            api.postDetails(postId).collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d { "getPostDetails Error State ${it.msg}" }
                    }
                    is Resources.Loading -> {
                        Logger.d { "getPostDetails Loading State ${it.isLoading}" }
                    }
                    is Resources.Success -> {
                        _postDetailsState.emit(it.data)
                        //Logger.d { "getPostDetails Success State ${it.data}" }
                    }
                }
            }
        }finally {
            _isLoading.emit(false)
        }
    }


    //getAllCommentRepo

    private suspend fun getAllComment(){
        _isCommentLoading.emit(true)
        try {
            api.getAllCommentRepo(postId).collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d { "getPostDetails Error State ${it.msg}" }
                    }
                    is Resources.Loading -> {
                        Logger.d { "getPostDetails Loading State ${it.isLoading}" }
                    }
                    is Resources.Success -> {
                        it.data?.let { it1 -> _postCommentsState.emit(it1) }
                        Logger.d { "getPostDetails Success State ${it.data}" }
                    }
                }
            }
        }finally {
            _isCommentLoading.emit(false)
        }
    }

    private suspend fun submitComment(request: SubmitCommentResponse){
       // _isCommentLoading.emit(true)
        try {
            api.submitPostComment(request).collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d { "getPostDetails Error State ${it.msg}" }
                    }
                    is Resources.Loading -> {
                        Logger.d { "getPostDetails Loading State ${it.isLoading}" }
                    }
                    is Resources.Success -> {
                        _postCommentSuccess.emit(true)
                        onSubmitCommentSuccess()
                        getAllComment()
                      //  it.data?.let { it1 -> _postCommentsState.emit(it1) }
                        Logger.d { "getPostDetails Success State ${it.data}" }
                    }
                }
            }
        }finally {
           // _isCommentLoading.emit(false)
        }
    }


    private suspend fun addLickRequest(request: LikeRequest) {
        api.addLick(request).collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "addLickRequest on Error State ${it.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "addLickRequest on Loading State ${it.isLoading}" }
                }
                is Resources.Success -> {
                    Logger.d { "addLickRequest on Success State  ${it.data}" }
                }
            }
        }
    }
    private fun onSubmitCommentSuccess() = coroutineScope.launch {
        //showToast("Comment")
        Logger.d { "Recall getAllComment After Comment Submit" }
        getAllComment()
    }

    fun onLick(request: LikeRequest) = coroutineScope.launch {
        addLickRequest(request = request)
    }


    fun callSubmitComment(request: SubmitCommentResponse) = coroutineScope.launch {
        submitComment(request)
    }
}