package tta.destinigo.talktoastro.feature_post.persentation


import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.FastOutSlowInEasing
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Card
import androidx.compose.material3.Divider
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_post.PostScreenComponent
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.response.PostDataNew
import tta.destinigo.talktoastro.resources.NotoColorEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoSansDevanagari_SemiCondensed_Regular
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_add_comment_24
import tta.destinigo.talktoastro.resources.baseline_thumb_up_24
import tta.destinigo.talktoastro.resources.roboto_regular
import tta.destinigo.talktoastro.resources.share_icon_new
import tta.destinigo.talktoastro.shared.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.shared.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.primaryColor

@OptIn(
    ExperimentalMaterial3WindowSizeClassApi::class,
    ExperimentalFoundationApi::class,
    ExperimentalMaterial3Api::class
)
@Composable
fun PostList(component: PostScreenComponent) {
    val post by component.postState.collectAsState()
    val loading by component.isLoading.collectAsState()
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.65f)
        WindowWidthSizeClass.Expanded -> Modifier.fillMaxWidth(0.7f)
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }
    val listState = rememberLazyListState()

    var showCreatePostDialog by remember { mutableStateOf(false) }

    if (loading) {
        HomePageLoadingProgressDialog(
            onDismissRequest = { },
            backgroundColor = Color(0xFF2A2A2A),
            loadingColor = Color.White
        )
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(background_color),
        contentAlignment = Alignment.Center
    ) {
        LazyColumn(
            modifier = widthModifier.fillMaxSize().background(background_color),
            state = listState
        ) {
            stickyHeader {
                CommonTopAppBar(
                    modifier = Modifier,
                    title = "Post",
                    onBackClick = { component.backClick() },
                    scrollBehavior = null
                )
            }
            items(post.size) { postInd ->
                val postData = post[postInd]
                PostItem(
                    postData, component,
                    onClick = { postClickData ->
                        component.postCardClick(postClickData.tableId.toString())
                    }
                )
                Spacer(Modifier.height(10.dp))
            }
        }

        // Animated visibility with smooth slide-in and slide-out animation
        AnimatedVisibility(
            visible = showCreatePostDialog,
            enter = slideInVertically(
                initialOffsetY = { it }, // Slide in from bottom to top
                animationSpec = tween(durationMillis = 500, easing = FastOutSlowInEasing) // Smooth slide-in
            ),
            exit = slideOutVertically(
                targetOffsetY = { it }, // Slide out from top to bottom
                animationSpec = tween(durationMillis = 500, easing = FastOutSlowInEasing) // Smooth slide-out
            )
        ) {
            AlertDialog(
                modifier = Modifier.padding(16.dp),
                containerColor = MaterialTheme.colorScheme.background,
                onDismissRequest =  { showCreatePostDialog = false },
                title = {
                    Text(
                        text = "Create Post",
                        style = MaterialTheme.typography.titleLarge,
                        color = MaterialTheme.colorScheme.primary
                    )
                },
                text = {
                    CreatePostScreen(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(MaterialTheme.colorScheme.background)
                    )
                },
                confirmButton = {
                    TextButton(onClick =  { showCreatePostDialog = false }) {
                        Text("Post", color = MaterialTheme.colorScheme.primary)
                    }
                },
                dismissButton = {
                    TextButton(onClick =  { showCreatePostDialog = false }) {
                        Text("Cancel", color = MaterialTheme.colorScheme.error)
                    }
                }
            )
        }

//        FloatingActionButton(
//            onClick = { showCreatePostDialog = true },
//            modifier = Modifier
//                .align(Alignment.BottomEnd)
//                .padding(16.dp),
//            containerColor = MaterialTheme.colorScheme.primary,
//            shape = CircleShape
//        ) {
//            Icon(
//                imageVector = Icons.Default.Add,
//                contentDescription = "Create Post",
//                tint = Color.White
//            )
//        }
    }

    LaunchedEffect(listState) {
        snapshotFlow { listState.layoutInfo.visibleItemsInfo }
            .collect { visibleItems ->
                val lastVisibleItemIndex = visibleItems.lastOrNull()?.index ?: -1
                if (lastVisibleItemIndex >= listState.layoutInfo.totalItemsCount - 1) {
                    component.loadNextPage()
                }
            }
    }
}



@Composable
fun PostItem(post: PostDataNew, component: PostScreenComponent, onClick: (PostDataNew) -> Unit) {
    Card {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .clickable {
                    onClick.invoke(post)
                }
                .background(Color.White)
                .padding(vertical = 8.dp)
        ) {
            Row(
                modifier = Modifier.padding(horizontal = 16.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                AsyncImage(
                    model = post.consultantProfile,
                    contentDescription = null,
                    modifier = Modifier
                        .size(30.dp)
                        .clip(CircleShape)
                        .background(Color.Gray)
                        .clickable { },
                    contentScale = ContentScale.Crop
                )

                Spacer(modifier = Modifier.width(8.dp))

                Column(modifier = Modifier.weight(1f)) {
                    Text(
                        text = post.expertName ?: "Unknown",
                        //style = MaterialTheme.typography.subtitle1,
                        color = Color.Black,
                        maxLines = 1,
                        style = TextStyle(
                            fontFamily = FontFamily(
                                Font(
                                    Res.font.roboto_regular,
                                    FontWeight.Normal
                                )
                            ),
                            fontSize = 16.sp
                        ),
                        overflow = TextOverflow.Ellipsis
                    )

                    if (!post.expertCategory.isNullOrEmpty()) {
                        Text(
                            text = post.expertCategory,
                            //style = MaterialTheme.typography.caption,
                            color = Color.Gray
                        )
                    }
                }

                Text(
                    text = "Subscribe",
                    style = MaterialTheme.typography.labelSmall,
                    // color = MaterialTheme.colors.primary,
                    modifier = Modifier
                        .clickable { showToast("In Development") }
                        .padding(8.dp)
                )
            }

            Spacer(modifier = Modifier.height(8.dp))

            Text(
                text = post.postCreationDate + " | " + post.postCreationTime,
                ///style = MaterialTheme.typography.caption,
                color = Color.Gray,
                modifier = Modifier.padding(horizontal = 16.dp)
            )
            Spacer(modifier = Modifier.height(8.dp))

            val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_SemiCondensed_Regular))
            val englishFont = FontFamily(Font(Res.font.roboto_regular))
            val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

            val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
            val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+") // Matches Hindi characters

            // Function to determine the font for each word
            fun getFontForWord(word: String): FontFamily {
                return when {
                    word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
                    word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
                    else -> englishFont // Default to English font
                }
            }

            // Function to create an AnnotatedString with proper font for each part of the text
            fun createStyledText(postDescription: String): AnnotatedString {
                val words = postDescription.split(" ")
                val annotatedString = buildAnnotatedString {
                    words.forEachIndexed { index, word ->
                        val fontFamily = getFontForWord(word)
                        withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                            append(word)
                        }
                        if (index < words.size - 1) append(" ") // Add space between words
                    }
                }
                return annotatedString
            }

            val styledText = post.postDescription?.let { createStyledText(it) } ?: AnnotatedString("")

            Text(
                text = styledText,
                style = MaterialTheme.typography.bodyMedium,
                fontSize = 16.sp,
                modifier = Modifier.padding(horizontal = 16.dp)
            )

            Spacer(modifier = Modifier.height(10.dp))
            if (!post.postContent.isNullOrEmpty()) {
                AsyncImage(
                    model = post.postContent,
                    contentDescription = null,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(250.dp)
                        .padding(horizontal = 16.dp)
                        .clip(RoundedCornerShape(4.dp))
                        .clickable { },
                    contentScale = ContentScale.Crop
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            HorizontalDivider(
                modifier = Modifier.padding(vertical = 8.dp),
                thickness = 1.dp,
                color = Color.Gray
            )
            Spacer(modifier = Modifier.height(8.dp))
            PostCommentLikeSection(
                post = post,
                onCommentClicked = { onClick.invoke(post) },
                onShareClicked = { showToast("In Development") },
                onLikeClicked = { lickPost ->
                    component.onLick(LikeRequest(postId = lickPost.tableId.toString(), like = if(lickPost.isLikedByMe()) 0 else 1))
                },
                modifier = Modifier.fillMaxWidth()
            )
        }
    }
}

@Composable
fun PostCommentLikeSection(
    post: PostDataNew,
    onLikeClicked: (PostDataNew) -> Unit,
    onCommentClicked: (PostDataNew) -> Unit,
    onShareClicked: (PostDataNew) -> Unit,
    modifier: Modifier = Modifier
) {
    val totalLikes = remember { mutableStateOf(post.totalLikes) }
    val totalComment = remember { mutableStateOf(post.totalComments) }
    val totalShare = remember { mutableStateOf(post.totalShares) }

    Row(
        modifier = modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        PostActionSection(
            count = totalLikes.value.toString(),
            label = "Like",
            icon = Res.drawable.baseline_thumb_up_24,
            isChecked = post.isLikedByMe(),
            onActionClicked = {
                // Increment the total likes
                totalLikes.value = totalLikes.value?.toIntOrNull()?.let { currentLikes ->
                    if (post.isLikedByMe()) {
                        (currentLikes - 1).toString()
                    } else {
                        (currentLikes + 1).toString()
                    }
                } ?: "0"

                // Handle like action
                onLikeClicked(post)
            }
            // iconColor = Color.Red
        )

        PostActionSection(
            count = totalComment.value ?: "0",
            label = "Comment",
            icon = Res.drawable.baseline_add_comment_24,
            onActionClicked = {
                onCommentClicked(post)
                totalComment.value = totalComment.value?.toInt()?.plus(1).toString()
            },
            //iconColor = Color.Blue
        )

        PostActionSection(
            count = totalShare.value ?: "0",
            label = "Share",
            icon = Res.drawable.share_icon_new,
            onActionClicked = {
                onShareClicked(post)
                totalShare.value = totalShare.value?.toInt()?.plus(1).toString()
            },
            // iconColor = Color.Green
        )
    }

    // Divider line
   // Divider(color = Color.Gray, thickness = 1.dp, modifier = Modifier.padding(vertical = 8.dp))
}

@Composable
fun PostActionSection(
    count: String,
    label: String,
    icon: DrawableResource,
    isChecked: Boolean = false,
    onActionClicked: () -> Unit,
    // iconColor: Color,
) {
    var isLiked by remember { mutableStateOf(isChecked) }

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(
            text = "$count $label",
            style = TextStyle(
                fontFamily = FontFamily(
                    Font(
                        Res.font.roboto_regular, // Replace with your font resource
                        FontWeight.Normal
                    )
                ),
                fontStyle = FontStyle.Normal, // Optional: Italicize or keep normal
                fontSize = 16.sp // Adjust size as needed
            ),
            modifier = Modifier.padding(bottom = 4.dp)
        )

        IconButton(onClick = {
            onActionClicked()
            if (label.equals("Like", true)) {
                isLiked = !isLiked
            }
        }) {
            Icon(
                painter = painterResource(icon),
                contentDescription = label,
                tint = if (isLiked) primaryColor else Color.Black
            )
        }

//        Text(
//            text = label,
//            style = TextStyle(
//                fontFamily = FontFamily(
//                    Font(
//                        Res.font.roboto_regular, // Replace with your font resource
//                        FontWeight.Normal
//                    )
//                ),
//                fontStyle = FontStyle.Normal, // Optional: Italicize or keep normal
//                fontSize = 16.sp // Adjust size as needed
//            )
//        )

    }
}


//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class,)
//@Composable
//fun PostList(component: PostScreenComponent) {
//    val post by component.postState.collectAsState()
//    val loading by component.isLoadingComplete.collectAsState()
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val isDesktop = windowSizeClass == WindowWidthSizeClass.Expanded
//
//    if (loading) {
//        HomePageLoadingProgressDialog(onDismissRequest = {})
//    } else {
//        val backgroundModifier = Modifier
//            .fillMaxSize()
//            .background(background_color)
//
//        Box(modifier = backgroundModifier, contentAlignment = Alignment.TopCenter) {
//            if (isDesktop) {
//                LazyVerticalGrid(
//                    columns = GridCells.Adaptive(300.dp),
//                    modifier = backgroundModifier.padding(16.dp),
//                    horizontalArrangement = Arrangement.spacedBy(16.dp),
//                    verticalArrangement = Arrangement.spacedBy(16.dp)
//                ) {
//                    items(post.size) { postIndx ->
//                        val postData = post[postIndx]
//                        PostItem(postData, isDesktop = true)
//                    }
//                }
//            } else {
//                LazyColumn(
//                    modifier = backgroundModifier.padding(16.dp),
//                    verticalArrangement = Arrangement.spacedBy(16.dp)
//                ) {
//                    items(post.size) { postIndx ->
//                        val postData = post[postIndx]
//                        PostItem(postData, isDesktop = true)
//                    }
//                }
//            }
//        }
//    }
//}
//
//@Composable
//fun PostItem(post: PostDataNew, isDesktop: Boolean) {
//    Card(
//        modifier = Modifier
//            .fillMaxWidth()
//            .padding(8.dp)
//            .hoverable()
//            .then(if (isDesktop) Modifier.padding(8.dp) else Modifier),
//        shape = RoundedCornerShape(12.dp),
//        elevation = CardDefaults.cardElevation(4.dp)
//    ) {
//        Column(
//            modifier = Modifier
//                .background(Color.White)
//                .padding(16.dp)
//        ) {
//            Row(
//                modifier = Modifier.padding(bottom = 8.dp),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                AsyncImage(
//                    model = post.consultantProfile,
//                    contentDescription = null,
//                    modifier = Modifier
//                        .size(if (isDesktop) 60.dp else 50.dp)
//                        .clip(CircleShape)
//                        .background(Color.Gray)
//                        .clickable { },
//                    contentScale = ContentScale.Crop
//                )
//
//                Spacer(modifier = Modifier.width(16.dp))
//
//                Column(modifier = Modifier.weight(1f)) {
//                    Text(
//                        text = post.expertName ?: "Unknown",
//                        style = MaterialTheme.typography.titleMedium,
//                        color = Color.Black,
//                        maxLines = 1,
//                        overflow = TextOverflow.Ellipsis
//                    )
//
//                    if (!post.expertCategory.isNullOrEmpty()) {
//                        Text(
//                            text = post.expertCategory,
//                            style = MaterialTheme.typography.bodySmall,
//                            color = Color.Gray
//                        )
//                    }
//                }
//
//                Button(
//                    onClick = { /* Subscribe action */ },
//                    modifier = Modifier.padding(horizontal = 8.dp)
//                ) {
//                    Text("Subscribe")
//                }
//            }
//
//            Text(
//                text = "${post.postCreationDate} | ${post.postCreationTime}",
//                style = MaterialTheme.typography.bodySmall,
//                color = Color.Gray,
//                modifier = Modifier.padding(vertical = 8.dp)
//            )
//
//            Text(
//                text = post.postDescription ?: "",
//                style = MaterialTheme.typography.bodyMedium,
//                modifier = Modifier.padding(bottom = 8.dp)
//            )
//
//            if (!post.postContent.isNullOrEmpty()) {
//                AsyncImage(
//                    model = post.postContent,
//                    contentDescription = null,
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .height(250.dp)
//                        .clip(RoundedCornerShape(8.dp))
//                        .clickable { },
//                    contentScale = ContentScale.Crop
//                )
//            }
//
//            Spacer(modifier = Modifier.height(16.dp))
//
//            PostCommentLikeSection(
//                post = post,
//                modifier = Modifier.fillMaxWidth()
//            )
//        }
//    }
//}

